import { FC, useContext, useEffect, useState } from 'react';
import {
  GridColDef,
  DataGridPro,
  GridFooterContainer,
  GridFooter,
  GridSlotsComponentsProps,
} from '@mui/x-data-grid-pro';
import { Box, IconButton, Link, Tooltip, Typography } from '@mui/material';
import { AppContext } from '../../../../hooks/context';
import {
  Family,
  FamilyDataGridElement,
  FamilyTabType,
} from '../../../../types';
import { parseDateTimeString } from '../../../../utils';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

declare module '@mui/x-data-grid' {
  interface FooterPropsOverrides {
    duration: string;
  }
}

const TimeTrackingListFooter = (
  props: NonNullable<GridSlotsComponentsProps['footer']>,
) => {
  return (
    <GridFooterContainer>
      {props.duration && (
        <Box>
          <Typography sx={{ ml: 1 }}>Insgesamt: {props.duration}</Typography>
        </Box>
      )}
      <GridFooter sx={{ border: 'none' }} />
    </GridFooterContainer>
  );
};

export default TimeTrackingListFooter;
