import moment from 'moment';
import { useEffect, useState } from 'react';

interface CountUpTimerProps {
  startTime?: number;
}

const CountUpTimer = (props: CountUpTimerProps): JSX.Element => {
  const [diff, setDiff] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      let timeDiff = 0;
      if (props.startTime) {
        timeDiff = moment().unix() - props.startTime;
      }
      setDiff(timeDiff);
    }, 1000);

    //remove interval after component dismiss
    return () => clearInterval(intervalId);
  }, [props.startTime]);

  return (
    <>
      {moment
        .utc(moment.duration(props.startTime ? diff : 0, 's').as('ms'))
        .format('HH:mm:ss')}
    </>
  );
};

export default CountUpTimer;
