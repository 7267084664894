import { FC, useContext, useEffect, useRef, useState } from 'react';
import {
  GridColDef,
  DataGridPro,
  GridFooterContainer,
  GridFooter,
  GridSlotsComponentsProps,
  GridToolbarContainer,
  GridToolbar,
} from '@mui/x-data-grid-pro';
import {
  Box,
  Button,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from '@mui/material';
import { AppContext } from '../../../../hooks/context';
import {
  Family,
  FamilyDataGridElement,
  FamilyTabType,
} from '../../../../types';
import { parseDateTimeString } from '../../../../utils';
import { useNavigate } from 'react-router-dom';
import moment, { Moment } from 'moment';
import CountUpTimer from '../../../core/count-up-timer/count-up-timer';

declare module '@mui/x-data-grid' {
  interface ToolbarPropsOverrides {
    showDayTimer?: boolean;
    showSubTimer?: boolean;
    dayTimer?: Moment;
    subTimer?: Moment;
  }
}

const TimeTrackingListToolbar = (
  props: NonNullable<GridSlotsComponentsProps['toolbar']>,
) => {
  const [dayTimer, setDayTimer] = useState<undefined | Moment>(props.dayTimer);
  const [subTimer, setSubTimer] = useState<undefined | Moment>(props.subTimer);

  useEffect(() => {
    setDayTimer(props.dayTimer);
  }, [props.dayTimer]);

  useEffect(() => {
    setSubTimer(props.subTimer);
  }, [props.subTimer]);

  return (
    <Box>
      {props.showDayTimer && (
        <Box sx={{ mx: 1 }}>
          <Button
            onClick={() => {
              if (dayTimer) setDayTimer(undefined);
              else setDayTimer(moment());
            }}
          >
            {dayTimer ? (
              <CountUpTimer startTime={dayTimer.unix()} />
            ) : (
              <Typography>Tag Starten</Typography>
            )}
          </Button>
        </Box>
      )}
      {props.showSubTimer && (
        <Box sx={{ mx: 1 }}>
          <Button
            onClick={() => {
              if (subTimer) setSubTimer(undefined);
              else setSubTimer(moment());
            }}
          >
            {subTimer ? (
              <CountUpTimer startTime={subTimer.unix()} />
            ) : (
              <Typography>Sub Task Starten</Typography>
            )}
          </Button>
        </Box>
      )}
      <GridToolbar />
    </Box>
  );
};

export default TimeTrackingListToolbar;
