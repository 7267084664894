import { FC, useContext, useEffect, useState } from 'react';
import { GridColDef, DataGridPro } from '@mui/x-data-grid-pro';
import { Box, IconButton, Link, Tooltip, Typography } from '@mui/material';
import { AppContext } from '../../../../hooks/context';
import {
  Family,
  FamilyDataGridElement,
  FamilyTabType,
} from '../../../../types';
import { parseDateTimeString } from '../../../../utils';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import TimeTrackingListFooter from '../time-tracking-list-footer/time-tracking-list-footer';

const TimeTrackingListDetail: FC<{
  timeTracking: any[];
}> = (props) => {
  // const context = useContext(AppContext);
  // const navigate = useNavigate();

  const tableColumns: Array<GridColDef> = [
    {
      field: 'client',
      headerName: 'CLIENT',
      flex: 1,
      hideable: false,
    },
    {
      field: 'type',
      headerName: 'TYPE',
      flex: 1,
      hideable: false,
    },
    {
      field: 'start',
      headerName: 'START',
      flex: 1.5,
      hideable: false,
    },
    {
      field: 'end',
      headerName: 'END',
      flex: 1,
    },
    {
      field: 'duration',
      headerName: 'DURATION',
      flex: 1,
    },
  ];

  return (
    <DataGridPro
      sx={{
        fontSize: 17,
        mx: 1,
      }}
      columns={tableColumns}
      rows={props.timeTracking}
      density="compact"
      getRowId={(row) => row.id}
      disableColumnSelector={true}
      disableColumnFilter={true}
      disableColumnMenu={true}
      hideFooterRowCount={true}
      hideFooterSelectedRowCount={true}
      columnBufferPx={4}
      autoHeight
      slots={{
        footer: TimeTrackingListFooter,
      }}
      slotProps={{
        footer: { duration: '10h 30m' },
      }}
    />
  );
};

export default TimeTrackingListDetail;
