import { ClientDataType } from '@casecare/types';
import {
  Box,
  CircularProgress,
  Dialog,
  Fab,
  Paper,
  Typography,
} from '@mui/material';
import { AxiosError } from 'axios';
import { FC, useState, useContext, useEffect } from 'react';
import { useQueryClient, useQuery } from 'react-query';
import { Link, useParams } from 'react-router-dom';
import { ClientTabs } from '../..';
import { ClientApi, FormApi, RiskApi } from '../../../api';
import {
  ClientFamilyTable,
  FormCategory,
  Mood,
  RiskAssessmentForm,
  RiskAssessmentTable,
} from '../../../components/client';
import {
  ProtocolTable,
  ReportTable,
} from '../../../components/client/documentation';
import {
  DiscardDialog,
  RiskAssessmentInformationDialog,
} from '../../../components/dialogs';
import { AppContext } from '../../../hooks/context';
import { useSavePrompt } from '../../../hooks/useBlocker';
import {
  CategoryData,
  FormCategoryData,
  RiskAssessmentQuestion,
  RiskAssessmentAnswer,
  ClientTabType,
  ClientSubTabType,
  DocumentationSubTabType,
  NoteSubTabType,
} from '../../../types';
import {
  RiskAssessment,
  RiskAssessmentSurvey,
  RiskAssessmentSurveyResult,
} from '../../../types/risk-assesment';
import {
  setDocumentTitle,
  loadTitle,
  getJson,
  evaluateChildRiskAssessment,
} from '../../../utils';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';
import { PermissionsTable } from '../../../components/client/permissions';
import TabPanel from '../../../components/core/tab-panel/tab-panel';
import ClientReferencePersonTable from '../../../components/client/client-refernce-person-table/client-reference-person-table';
import { RiskSubTabType } from '../../../types/risk-sub-tab-type';
import SubTabNav from '../../../components/core/sub-tab-nav/sub-tab-nav';
import { AvatarNotes, GeneralNotes } from '../../../components/client/notes';
import EcoMapTable from '../../../components/eco-map/eco-map-table/eco-map-table';
import { faFloppyDisk, faM } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ClientDataPersonal,
  ClientDataExtended,
} from '../../../components/client/client-data';
import { MenuWrapper } from '../../../components/core';
import TodoList from '../../../components/planning/todo-list/todo-list';
import moment from 'moment';
import ClientDataHelpFrom from '../../../components/client/client-data/client-data-help-form/client-data-help-form';
import { BreadCrumbSegment } from '../../../types/breadcrumb-segment';
import { StorageTable } from '../../../components/storage';
import StorageContainer from '../../../components/core/storage/storage-container';

const ClientDetails: FC<{ isReferencePerson?: boolean }> = (props) => {
  const context = useContext(AppContext);
  const queryClient = useQueryClient();
  const { clientId, tab, subtab } = useParams();

  const [categoryDataId, setCategoryDataId] = useState<string | undefined>();
  const [clientName, setClientName] = useState<string | undefined>();
  // Needed to use state variables instead of useQuery value directly to force refresh of form on clientId change
  const [categoryData, setCategoryData] = useState<any[]>([]);
  const [formData, setFormData] = useState([]);
  const [subCategories, setSubCategories] = useState<any>([]);
  const [riskData, setRiskData] = useState<RiskAssessmentSurvey[]>([]);
  const [showRiskAssessmentTab, setShowRiskAssessmentTab] = useState(
    tab === ClientTabType.RISK,
  );
  const [surveyId, setSurveyId] = useState('');
  const [riskId, setRiskId] = useState('');
  const [surveyRisks, setSurveyRisks] = useState<RiskAssessment[]>();
  const [surveyData, setSurveyData] = useState<RiskAssessmentQuestion[]>();
  const [surveyAnswers, setSurveyAnswers] = useState<RiskAssessmentAnswer[]>();
  const [availableSubTypes, setAvailableSubtypes] = useState([]);
  const [addedRiskId, setAddedRiskId] = useState<any>();
  const [openInfoDialog, setOpenInfoDialog] = useState(false);
  const [savedClientData, setSavedClientData] = useState(false);
  const [savedNotes, setSavedNotes] = useState(false);
  const [familyMembers, setFamilyMembers] = useState([]);
  const [referencePersons, setReferencePersons] = useState([]);
  const [storageItemIndex, setStorageItemIndex] = useState<any>(null);
  const [family, setFamily] = useState<any>(undefined);
  const [clientData, setClientData] =
    useState<Record<ClientDataType, string | string[]>>();
  const [refreshSubTabs, setRefreshSubTabs] = useState(false);
  const [selectedTab, setSelectedTab] = useState(ClientTabType.DATA.toString());
  const [selectedSubTab, setSelectedSubTab] = useState(subtab || '');
  const [futureSubTab, setFutureSubTab] = useState('');
  const [openSavePrompt, setOpenSavePrompt] = useState(false);
  const [surveyUrl, setSurveyUrl] = useState();
  const [clientSuffering, setClientSuffering] = useState({});
  const [savedClientSuffering, setSavedClientSuffering] = useState(false);

  // const [tabNav, setTabNav] = useState([
  //   {
  //     id: ClientTabType.DATA,
  //     name: `${context.i18n.clientData}`,
  //     href: `/client/details/${context.clientId}/clientData`,
  //   },
  //   {
  //     id: ClientTabType.ID,
  //     dynamicData: 'CATEGORY_DATA',
  //     href: `/client/details/${context.clientId}/__replace_id__`,
  //     hrefReplace: '__replace_id__',
  //   },
  //   {
  //     id: ClientTabType.DOCUMENTATION,
  //     name: `${context.i18n.documentation}`,
  //     href: `/client/details/${context.clientId}/documentation`,
  //   },
  //   {
  //     id: ClientTabType.PERMISSIONS,
  //     name: `${context.i18n.permissions}`,
  //     href: `/client/details/${context.clientId}/permissions`,
  //   },
  //   {
  //     id: ClientTabType.RISK,
  //     name: `${context.i18n.riskAssessment}`,
  //     href: `/client/details/${context.clientId}/risk`,
  //     hidden: showRiskAssessmentTab,
  //   },
  //   {
  //     id: ClientTabType.AVATAR,
  //     name: `${context.i18n.avatar}`,
  //     icon: faPenToSquare,
  //     href: `/avatar/details/${context.clientId}/${context.clientId}`,
  //     target: '_blank',
  //   },
  // ]);

  // sub-tab-nav
  const subTabNav = {
    [ClientTabType.DATA]: [
      {
        id: ClientSubTabType.CLIENT_DATA,
        name: `${context.i18n.clientTabClientDataClientData}`,
      },
      {
        id: ClientSubTabType.CLIENT_DATA_EXTENDED,
        name: context.i18n.clientTabClientDataClientDataExtended,
      },
      {
        id: ClientSubTabType.CLIENT_DATA_HELP_FORM,
        name: context.i18n.clientTabClientDataClientDataHelpForm,
      },
      {
        id: ClientSubTabType.CLIENT_DATA_CARE,
        name: context.i18n.clientTabClientDataClientDataCare,
        hidden: moment(clientData?.BIRTHDAY).isBefore(
          moment().subtract(18, 'years'),
        ),
      },
    ],
    [ClientTabType.SOCIAL_ENVIRONMENT]: family
      ? [
          {
            id: ClientSubTabType.FAMILY,
            name: `${context.i18n.familyMembers}`,
          },
          {
            id: ClientSubTabType.REFERENCE,
            name: `${context.i18n.referencePersons}`,
          },
        ]
      : [
          {
            id: ClientSubTabType.REFERENCE,
            name: `${context.i18n.clientTabSocialReferenceData}`,
          },
        ],
    [ClientTabType.SOCIAL_DIAGNOSTIC]: [
      {
        id: ClientSubTabType.ECOMAP,
        name: `${context.i18n.clientTabSocialdiagnosticEcoMap}`,
      },
      {
        id: ClientSubTabType.AVATAR,
        name: context.i18n.clientTabSocialdiagnosticAvatar,
        url: `/avatar/editor/${context.clientId}/${context.clientId}`,
        hidden: !context.avatarActive,
      },
      {
        id: ClientSubTabType.GENOGRAMM,
        name: `${context.i18n.clientTabSocialdiagnosticGenoGramm}`,
        url: `/geno-gramm/${context.clientId}`,
        hidden: !context.genoGrammActive,
      },
      {
        id: ClientSubTabType.MOOD,
        name: context.i18n.clientTabSocialdiagnosticMood,
      },
    ],
    [ClientTabType.DOCUMENTATION]: [
      {
        id: DocumentationSubTabType.PROTOCOL,
        name: `${context.i18n.protocols}`,
      },
      {
        id: DocumentationSubTabType.REPORT,
        name: `${context.i18n.reports}`,
      },
    ],
    [ClientTabType.NOTES]: [
      {
        id: NoteSubTabType.GENERAL,
        name: context.i18n.generalNotes,
      },
      {
        id: NoteSubTabType.AVATAR,
        name: context.i18n.avatarNotes,
      },
    ],
  };

  useEffect(() => {
    setAddedRiskId(undefined);
  }, [surveyId, riskId]);

  useEffect(() => {
    if (clientData) {
      const name = `${clientData.FIRST_NAME || ''} ${
        clientData.LAST_NAME || ''
      }`;
      setDocumentTitle(name);
      setClientName(name);
    }
    tab && changeSelected(tab);
  }, [clientData]);

  useEffect(() => {
    // custom breadcrumb //
    const breadCrumb: BreadCrumbSegment[] = [];
    breadCrumb.push({
      path: '/clients',
      name: context.i18n.siteTitleClients,
    });
    if (family)
      breadCrumb.push({
        path: `/families/${family.id}/familyMembers`,
        name: family.name,
      });
    breadCrumb.push({
      path: `/clients/${clientId}/clientData`,
      name: clientName ?? '',
    });
    const selectedTabObj = categoryData.find((a) => a.id == selectedTab);
    if (selectedTabObj) {
      const path = `/clients/${clientId}/${selectedTab}`;
      breadCrumb.push({
        path: path,
        name: selectedTabObj.label,
      });
      const selectedSubTabObj = selectedTabObj.childCategories.find(
        (a: any) => a.id == categoryDataId,
      );
      if (selectedSubTabObj)
        breadCrumb.push({
          path: `${path}/${categoryDataId}`,
          name: selectedSubTabObj.label,
        });
    } else {
      const path = `/clients/${clientId}/${selectedTab}`;
      const i18nKey =
        'clientTab' +
        `${(selectedTab.charAt(0).toUpperCase() + selectedTab.slice(1)).replace(
          /[_-]/g,
          '',
        )}`;
      breadCrumb.push({
        path: path,
        name: context.i18n[i18nKey],
      });
      if (selectedSubTab) {
        const selectedRiskData = riskData.find(
          (a: any) => a.id == selectedSubTab,
        );
        if (selectedRiskData && riskId) {
          if (riskId)
            breadCrumb.push({
              path: `${path}/${selectedSubTab}`,
              name:
                selectedRiskData.riskAssessments.find(
                  (a: any) => a.id == riskId,
                )?.name ?? '',
            });
        } else {
          breadCrumb.push({
            path: `${path}/${selectedSubTab}`,
            name: context.i18n[
              `${i18nKey}${(
                selectedSubTab.charAt(0).toUpperCase() + selectedSubTab.slice(1)
              ).replace(/[_-]/g, '')}`
            ],
          });
        }
      }
    }
    context.setBreadCrumbSegments(breadCrumb);
  }, [clientName, family, selectedTab, selectedSubTab, categoryDataId, riskId]);

  useEffect(() => {
    return () => {
      loadTitle();
    };
  }, []);

  useEffect(() => {
    setCategoryDataId(undefined);
    setCategoryData([]);
    setFormData([]);
    setRefreshSubTabs(!refreshSubTabs);
    context.setClientId ? context.setClientId(clientId) : null;
    context.setReadOnly && context.setReadOnly(false);
  }, [clientId]);

  const clientDataQuery = useQuery(
    ['clientData', clientId],
    () => ClientApi.getClientData(context.authToken, clientId),
    {
      enabled: context.clientId !== undefined,
      onSuccess: (res) => {
        context.setReadOnly && context.setReadOnly(res.data.archived);

        setClientData(res.data.data);
        setFamilyMembers(res.data.familyMembers);
        setReferencePersons(res.data.referencePersons);
        setFamily(res.data.family);
        setStorageItemIndex(res.data.storageItemIndex);
      },
      onError: (e) => {
        const err = e as AxiosError;
        const errorData = err.response?.data;
        errorData.detail.forEach((message: string) => {
          snackbarUtils.error(message);
        });
      },
    },
  );

  const fData = useQuery(
    ['formData', categoryDataId],
    () => FormApi.getCategoryData(clientId, categoryDataId, context.authToken),
    {
      enabled: categoryDataId !== undefined && !clientDataQuery.isLoading,
      onSuccess: (res) => {
        setFormData(res.data);
      },
      onError: (e) => {
        const err = e as AxiosError;
        const errorData = err.response?.data;
        errorData.detail.forEach((message: string) => {
          snackbarUtils.error(message);
        });
      },
    },
  );

  const cData = useQuery(
    ['categoryData', clientId],
    () => ClientApi.getCategoriesAndRisks(context.authToken, clientId),
    {
      enabled:
        !clientDataQuery.isLoading &&
        context.authToken !== undefined &&
        context.isAdmin,
      onSuccess: (res) => {
        setFormCategories(res.data.form);
        setRiskData(res.data.risk);
        setShowRiskAssessmentTab(
          res.data.risk.length > 0 || tab === ClientTabType.RISK,
        );
      },
      onError: (e) => {
        const err = e as AxiosError;
        const errorData = err.response?.data;
        errorData.detail.forEach((message: string) => {
          snackbarUtils.error(message);
        });
      },
    },
  );

  useQuery(
    ['availableRisks', surveyId],
    () => RiskApi.getAvailableRisks(context.authToken, surveyId),
    {
      enabled: surveyId !== '' && !clientDataQuery.isLoading,
      onSuccess: async (res) => {
        setAvailableSubtypes(res.data.data);
      },
      onError: (e) => {
        const err = e as AxiosError;
        const errorData = err.response?.data;
        errorData.detail.forEach((message: string) => {
          snackbarUtils.error(message);
        });
      },
    },
  );

  const setFormCategories = (forms: any) => {
    setCategoryData(
      forms.sort((a: CategoryData, b: CategoryData) => a.seq - b.seq),
    );
    if (!categoryDataId && forms.length > 0) {
      const defaultCategory = forms.filter(
        (category: CategoryData) => category.default,
      );
      if (defaultCategory && defaultCategory.length > 0) {
        if (!showRiskAssessmentTab) {
          setCategoryDataId(defaultCategory[0].id);
        }
      }
      if (defaultCategory && defaultCategory.length > 0) {
        if (defaultCategory[0].childCategories) {
          const childCategories: CategoryData[] =
            defaultCategory[0].childCategories.sort(
              (a: CategoryData, b: CategoryData) => a.seq - b.seq,
            );
          setSubCategories(childCategories);
          if (!showRiskAssessmentTab) {
            setCategoryDataId(childCategories[0].id);
          }
        }
      }
    }
  };

  const rData = useQuery(
    ['risk', surveyId],
    () => RiskApi.getSurveyRisks(context.authToken, surveyId),
    {
      enabled: surveyId !== '' && !clientDataQuery.isLoading,
      onSuccess: async (res) => {
        if (addedRiskId) {
          setRiskId(addedRiskId);
        } else {
          setRiskId(res.data[0].id);
        }
        setSurveyRisks(res.data);
      },
      onError: (e) => {
        const err = e as AxiosError;
        const errorData = err.response?.data;
        errorData.detail.forEach((message: string) => {
          snackbarUtils.error(message);
        });
      },
    },
  );

  useQuery(
    ['survey', surveyId, riskId],
    () => RiskApi.getSurvey(context.authToken, riskId, surveyId),
    {
      enabled: surveyId !== '' && riskId !== '' && !rData.isLoading,
      onSuccess: async (res) => {
        setSurveyUrl(res.data.surveyUrl);
        setSurveyAnswers(res.data.answers);
      },
      onError: (e) => {
        console.error(e);
        const err = e as AxiosError;
        const errorData = err.response?.data;
        errorData.detail.forEach((message: string) => {
          snackbarUtils.error(message);
        });
      },
    },
  );

  useQuery(
    ['suffering', clientId],
    () => ClientApi.getClientSuffering(context.authToken, clientId),
    {
      enabled:
        context.authToken !== undefined &&
        selectedSubTab === ClientSubTabType.MOOD,
      onSuccess: async (res) => {
        setClientSuffering(res.data.data);
      },
      onError: (e) => {
        console.error(e);
        const err = e as AxiosError;
        const errorData = err.response?.data;
        errorData.detail.forEach((message: string) => {
          snackbarUtils.error(message);
        });
      },
    },
  );

  const getSurveyData = async () => {
    if (!surveyUrl) {
      queryClient.invalidateQueries('survey');
    } else {
      const surveyData = await getJson(surveyUrl);
      setSurveyData(surveyData.questions);
    }
  };

  useEffect(() => {
    getSurveyData();
  }, [surveyUrl]);

  const changeSelected = (id: string) => {
    setSelectedTab(id);
    const category = categoryData.find((category) => category.id === id);
    if (category === undefined) {
      return;
    }
    if (category?.childCategories) {
      const childCategories: CategoryData[] = category?.childCategories.sort(
        (a: CategoryData, b: CategoryData) => a.seq - b.seq,
      );
      setCategoryDataId(childCategories[0].id);
      setSubCategories(childCategories);
    } else {
      setCategoryDataId(id);
      setSubCategories([]);
    }
  };

  const deleteRiskAssessment = async (id: string) => {
    await RiskApi.deleteRiskAssessment(context.authToken, id);
    queryClient.invalidateQueries('categoryData');
  };

  const changeSubRiskassement = (next: boolean) => {
    if (surveyRisks) {
      if (next) {
        setRiskId(
          surveyRisks[surveyRisks?.findIndex((risk) => risk.id === riskId) + 1]
            .id,
        );
      } else {
        setRiskId(
          surveyRisks[surveyRisks?.findIndex((risk) => risk.id === riskId) - 1]
            .id,
        );
      }
    }
  };

  const updateResult = async (
    result: RiskAssessmentSurveyResult,
    riskId: string,
  ) => {
    const oldResult = riskData.find((survey) => survey.id === surveyId)?.result;
    if (oldResult === result || riskId === '') {
      return;
    }

    let unfinished = result === RiskAssessmentSurveyResult.UNFINISHED;
    let highRisk = result === RiskAssessmentSurveyResult.HIGH_RISK;
    let mediumRisk = result === RiskAssessmentSurveyResult.MEDIUM_RISK;
    const noRisk = result === RiskAssessmentSurveyResult.NO_RISK;

    const atRiskSubTypes = [];

    if (highRisk || mediumRisk) {
      atRiskSubTypes.push(
        surveyRisks?.find((risk) => risk.id === riskId)?.name,
      );
    }

    try {
      for (const risk of surveyRisks ? surveyRisks : []) {
        if (risk.id === riskId) {
          continue;
        }
        const survey = await RiskApi.getSurvey(
          context.authToken,
          risk.id,
          surveyId,
        );
        const surveyData = await getJson(survey.data.surveyUrl);
        const answers = survey.data.answers;

        const result = evaluateChildRiskAssessment(
          surveyData.questions,
          answers,
        );
        if (result === RiskAssessmentSurveyResult.HIGH_RISK) {
          highRisk = true;
          atRiskSubTypes.push(risk.name);
        }
        if (result === RiskAssessmentSurveyResult.MEDIUM_RISK) {
          mediumRisk = true;
          atRiskSubTypes.push(risk.name);
        }
        if (result === RiskAssessmentSurveyResult.UNFINISHED) {
          unfinished = true;
        }
      }

      let finalResult = RiskAssessmentSurveyResult.UNFINISHED;

      if (unfinished) {
        if (highRisk) {
          finalResult = RiskAssessmentSurveyResult.HIGH_RISK_UNFINISHED;
        } else if (mediumRisk) {
          finalResult = RiskAssessmentSurveyResult.MEDIUM_RISK_UNFINISHED;
        }
      } else {
        if (highRisk) {
          finalResult = RiskAssessmentSurveyResult.HIGH_RISK;
        } else if (mediumRisk) {
          finalResult = RiskAssessmentSurveyResult.MEDIUM_RISK;
        } else if (noRisk) {
          finalResult = RiskAssessmentSurveyResult.NO_RISK;
        }
      }

      await RiskApi.updateResult(
        context.authToken,
        surveyId,
        finalResult,
        atRiskSubTypes,
      );
      queryClient.invalidateQueries('survey');
      queryClient.invalidateQueries('categoryData');
    } catch (e) {
      snackbarUtils.error(context.i18n.error);
    }
  };

  const onSave = () => {
    context.saveChanges();
    context.setUnsavedChanges(false);
    setSavedClientData(true);
    setSavedNotes(true);
    setSavedClientSuffering(true);
  };

  const onDiscard = () => {
    context.saveChanges();
    context.setUnsavedChanges(false);
  };

  useSavePrompt(
    onSave,
    () => {
      onDiscard();
      setTimeout(() => window.location.reload(), 50);
    },
    context.unsavedChanges,
  );

  useEffect(() => {
    setOpenSavePrompt(context.unsavedChanges);
  }, [futureSubTab]);

  useEffect(() => {
    if (savedClientData) {
      setSavedClientData(false);
    }
  }, [savedClientData]);

  useEffect(() => {
    if (savedClientSuffering) {
      setSavedClientSuffering(false);
    }
  }, [savedClientSuffering]);

  useEffect(() => {
    if (savedNotes) {
      setSavedNotes(false);
    }
  }, [savedNotes]);

  useEffect(() => {
    setFamily(undefined);
  }, []);

  return (
    <MenuWrapper
      customBreadCrumb
      title={clientName || ''}
      subTitle={
        context.readOnly ? context.i18n.showClient : context.i18n.editClient
      }
      search
      addClient
    >
      {cData.isLoading || clientDataQuery.isLoading ? (
        <CircularProgress
          sx={{ position: 'absolute', top: '50%', left: '50%' }}
        />
      ) : (
        <Box>
          {/* <TabNav
            selected={selectedTab}
            tabs={tabNav}
            dynamicTabs={{ CATEGORY_DATA: categoryData }}
            setSelected={(id) => changeSelected(id)}
          /> */}
          <ClientTabs
            onSave={onSave}
            selected={selectedTab}
            setId={(id) => changeSelected(id)}
            categoryTabs={categoryData}
            showRiskAssessment={showRiskAssessmentTab}
            hideTabs={props.isReferencePerson}
          />
          <TabPanel
            value={selectedTab}
            index={ClientTabType.TODO}
            defaultSubTab={() => setSelectedSubTab('')}
          >
            <SubTabNav subTabs={[]}></SubTabNav>
            <Paper
              sx={{
                maxHeight: '80vh',
                m: 3,
                mt: 0,
                flexGrow: 1,
                overflowY: 'auto',
                borderRadius: 0,
                p: 5,
              }}
            >
              <TodoList
                clientId={clientId}
                title={context.i18n.todoListTitle}
                done={false}
              />
              <TodoList
                clientId={clientId}
                title={context.i18n.doneListTitle}
                done={true}
              />
            </Paper>
          </TabPanel>
          <TabPanel
            value={selectedTab}
            index={ClientTabType.DATA}
            defaultSubTab={() =>
              setSelectedSubTab(subtab || ClientSubTabType.CLIENT_DATA)
            }
          >
            <SubTabNav
              subTabs={subTabNav[ClientTabType.DATA]}
              selected={selectedSubTab}
              setSelected={(id) => {
                if (context.unsavedChanges) {
                  setFutureSubTab(id);
                } else {
                  setSelectedSubTab(id);
                }
              }}
            ></SubTabNav>
            <TabPanel
              value={selectedSubTab}
              index={ClientSubTabType.CLIENT_DATA}
            >
              {clientData && (
                <ClientDataPersonal
                  // clientFamilies={families}
                  saved={savedClientData}
                  clientData={clientData}
                  isReferencePerson={
                    props.isReferencePerson ? props.isReferencePerson : false
                  }
                />
              )}
              {context.unsavedChanges && (
                <Fab
                  onClick={onSave}
                  color="success"
                  sx={{ position: 'fixed', bottom: 20, right: 20 }}
                >
                  <FontAwesomeIcon icon={faFloppyDisk} color="white" />
                </Fab>
              )}
            </TabPanel>
            <TabPanel
              value={selectedSubTab}
              index={ClientSubTabType.CLIENT_DATA_EXTENDED}
            >
              {clientData && (
                <ClientDataExtended
                  clientData={clientData}
                  saved={savedClientData}
                />
              )}
              {context.unsavedChanges && (
                <Fab
                  onClick={onSave}
                  color="success"
                  sx={{ position: 'fixed', bottom: 20, right: 20 }}
                >
                  <FontAwesomeIcon icon={faFloppyDisk} color="white" />
                </Fab>
              )}
            </TabPanel>
            <TabPanel
              value={selectedSubTab}
              index={ClientSubTabType.CLIENT_DATA_HELP_FORM}
            >
              {clientData && (
                <ClientDataHelpFrom
                  clientData={clientData}
                  saved={savedClientData}
                />
              )}
            </TabPanel>
            <TabPanel
              value={selectedSubTab}
              index={ClientSubTabType.CLIENT_DATA_CARE}
            >
              {clientData && (
                <ClientDataExtended
                  care
                  clientData={clientData}
                  saved={savedClientData}
                />
              )}
            </TabPanel>
          </TabPanel>
          <TabPanel
            value={selectedTab}
            index={ClientTabType.SOCIAL_ENVIRONMENT}
            defaultSubTab={() =>
              family
                ? setSelectedSubTab(ClientSubTabType.FAMILY)
                : setSelectedSubTab(ClientSubTabType.REFERENCE)
            }
          >
            <SubTabNav
              subTabs={subTabNav[ClientTabType.SOCIAL_ENVIRONMENT]}
              selected={selectedSubTab}
              setSelected={setSelectedSubTab}
            />
            <TabPanel value={selectedSubTab} index={ClientSubTabType.FAMILY}>
              <ClientFamilyTable
                clientId={clientId}
                family={family}
                familyMembers={familyMembers}
              />
            </TabPanel>
            <TabPanel value={selectedSubTab} index={ClientSubTabType.REFERENCE}>
              <ClientReferencePersonTable
                family={family}
                referencePersons={referencePersons}
              />
            </TabPanel>
            <TabPanel value={selectedSubTab} index={ClientSubTabType.ECOMAP}>
              <EcoMapTable clientId={clientId} />
            </TabPanel>
          </TabPanel>
          <TabPanel
            value={selectedTab}
            index={ClientTabType.SOCIAL_DIAGNOSTIC}
            defaultSubTab={() => setSelectedSubTab(ClientSubTabType.ECOMAP)}
          >
            <SubTabNav
              subTabs={subTabNav[ClientTabType.SOCIAL_DIAGNOSTIC]}
              selected={selectedSubTab}
              setSelected={setSelectedSubTab}
            />
            <TabPanel value={selectedSubTab} index={ClientSubTabType.ECOMAP}>
              <EcoMapTable clientId={clientId} />
            </TabPanel>
            <TabPanel value={selectedSubTab} index={ClientSubTabType.MOOD}>
              <Mood
                clientId={clientId}
                saved={savedClientSuffering}
                initialValues={clientSuffering}
              />
              {context.unsavedChanges && (
                <Fab
                  onClick={onSave}
                  color="success"
                  sx={{ position: 'fixed', bottom: 20, right: 20 }}
                >
                  <FontAwesomeIcon icon={faFloppyDisk} color="white" />
                </Fab>
              )}
            </TabPanel>
          </TabPanel>
          <TabPanel
            value={selectedTab}
            index={ClientTabType.DOCUMENTATION}
            defaultSubTab={() =>
              setSelectedSubTab(DocumentationSubTabType.PROTOCOL)
            }
          >
            <SubTabNav
              setSelected={setSelectedSubTab}
              selected={selectedSubTab}
              subTabs={subTabNav[ClientTabType.DOCUMENTATION]}
            ></SubTabNav>
            <TabPanel
              value={selectedSubTab}
              index={DocumentationSubTabType.PROTOCOL}
            >
              <ProtocolTable
                supervisorIds={
                  Array.isArray(clientData?.SUPERVISOR)
                    ? clientData?.SUPERVISOR
                    : []
                }
                name={clientName}
                clientId={clientId}
                familyId={family?.id}
              />
            </TabPanel>
            <TabPanel
              value={selectedSubTab}
              index={DocumentationSubTabType.REPORT}
            >
              <ReportTable
                name={clientName}
                supervisorId={clientData?.SUPERVISOR[0]}
              />
            </TabPanel>
          </TabPanel>
          <TabPanel
            value={selectedTab}
            index={ClientTabType.STORAGE}
            defaultSubTab={() => setSelectedSubTab('')}
          >
            <SubTabNav subTabs={[]}></SubTabNav>
            <StorageContainer
              storageItemIndexId={storageItemIndex?.id}
              storageItemId={storageItemIndex?.id}
            />
          </TabPanel>
          <TabPanel
            value={selectedTab}
            index={ClientTabType.PERMISSIONS}
            defaultSubTab={() => setSelectedSubTab('')}
          >
            <SubTabNav subTabs={[]}></SubTabNav>
            <PermissionsTable clientId={clientId} />
          </TabPanel>
          <TabPanel
            defaultSubTab={() => setSelectedSubTab(NoteSubTabType.GENERAL)}
            value={selectedTab}
            index={ClientTabType.NOTES}
          >
            <SubTabNav
              selected={selectedSubTab}
              setSelected={(id) => {
                setSelectedSubTab(id);
                queryClient.invalidateQueries('getGeneralNotes');
              }}
              subTabs={subTabNav[ClientTabType.NOTES]}
            ></SubTabNav>
            <TabPanel value={selectedSubTab} index={NoteSubTabType.GENERAL}>
              <GeneralNotes saved={savedNotes} />
            </TabPanel>
            <TabPanel value={selectedSubTab} index={NoteSubTabType.AVATAR}>
              <AvatarNotes saved={savedNotes} />
            </TabPanel>
          </TabPanel>

          <TabPanel
            value={selectedTab}
            index={ClientTabType.RISK}
            defaultSubTab={() => setSelectedSubTab(RiskSubTabType.RISKTABLE)}
          >
            {selectedSubTab === RiskSubTabType.RISKTABLE && (
              <SubTabNav subTabs={[]}></SubTabNav>
            )}
            <TabPanel value={selectedSubTab} index={RiskSubTabType.RISKTABLE}>
              <RiskAssessmentTable
                riskData={riskData}
                deleteRiskAssessment={deleteRiskAssessment}
                openRiskAssessment={(id: string, newRisk?: boolean) => {
                  setOpenInfoDialog(newRisk ? newRisk : false);
                  setSurveyId(id);
                  setSelectedSubTab(id);
                }}
              />
            </TabPanel>
            {riskData.map((risk, index) => (
              <TabPanel
                key={index}
                value={selectedSubTab}
                index={risk.id}
                defaultSubTab={() =>
                  setRiskId(
                    surveyRisks && surveyRisks.length > 0
                      ? surveyRisks[0].id
                      : '',
                  )
                }
              >
                <SubTabNav
                  setSelected={setRiskId}
                  selected={riskId}
                  subTabs={surveyRisks ? surveyRisks : []}
                  onBack={() => setSelectedSubTab(RiskSubTabType.RISKTABLE)}
                ></SubTabNav>
                {surveyRisks &&
                  surveyRisks.map((survey, index) => (
                    <TabPanel key={index} value={riskId} index={survey.id}>
                      {surveyData === undefined ? (
                        <>
                          <CircularProgress />
                        </>
                      ) : (
                        <RiskAssessmentForm
                          name={
                            surveyRisks?.find((risk) => risk.id === riskId)
                              ?.name
                          }
                          questions={surveyData ? surveyData : []}
                          answers={surveyAnswers ? surveyAnswers : []}
                          surveyId={surveyId}
                          riskId={riskId}
                          updateResult={updateResult}
                          changeSubRiskassement={changeSubRiskassement}
                          availableSubTypes={availableSubTypes}
                          onAdd={(id) => setAddedRiskId(id)}
                          disablePreviousRiskChange={
                            surveyRisks &&
                            surveyRisks?.findIndex(
                              (survey) => survey.id === riskId,
                            ) === 0
                          }
                          disableNextRiskChange={
                            surveyRisks &&
                            surveyRisks?.findIndex(
                              (survey) => survey.id === riskId,
                            ) +
                              1 ===
                              surveyRisks?.length
                          }
                        />
                      )}
                      <RiskAssessmentInformationDialog
                        open={openInfoDialog}
                        onClose={() => setOpenInfoDialog(false)}
                      />
                    </TabPanel>
                  ))}
              </TabPanel>
            ))}
          </TabPanel>
          {categoryData.map((category, index) => (
            <TabPanel
              key={index}
              value={selectedTab}
              index={category.id}
              defaultSubTab={() =>
                setCategoryDataId(
                  subCategories.length > 0
                    ? subCategories[0].id
                    : categoryDataId,
                )
              }
            >
              <SubTabNav
                setSelected={setCategoryDataId}
                selected={categoryDataId}
                subTabs={subCategories}
              ></SubTabNav>
              {fData.isLoading ? (
                <CircularProgress
                  sx={{ position: 'absolute', top: '50%', left: '50%' }}
                />
              ) : (
                <FormCategory
                  data={formData.sort(
                    (a: FormCategoryData, b: FormCategoryData) => a.seq - b.seq,
                  )}
                />
              )}
            </TabPanel>
          ))}
        </Box>
      )}
      <Dialog open={openSavePrompt}>
        <DiscardDialog
          onClose={() => {
            setFutureSubTab('');
            setOpenSavePrompt(false);
          }}
          onDiscard={() => {
            onDiscard();
            setOpenSavePrompt(false);
            setTimeout(() => setSelectedSubTab(futureSubTab), 50);
            setFutureSubTab('');
          }}
          onSave={() => {
            onSave();
            setOpenSavePrompt(false);
            setTimeout(() => setSelectedSubTab(futureSubTab), 50);
            setFutureSubTab('');
          }}
        />
      </Dialog>
    </MenuWrapper>
  );
};

export default ClientDetails;
