import { FC, useContext, useEffect, useState } from 'react';
import { GridColDef, DataGridPro, GridFooter } from '@mui/x-data-grid-pro';
import { Box, IconButton, Link, Tooltip, Typography } from '@mui/material';
import { AppContext } from '../../../hooks/context';
import { Family, FamilyDataGridElement, FamilyTabType } from '../../../types';
import { parseDateTimeString } from '../../../utils';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { TimeTrackingListDetail } from '..';
import TimeTrackingListFooter from './time-tracking-list-footer/time-tracking-list-footer';
import TimeTrackingListToolbar from './time-tracking-list-toolbar/time-tracking-list-toolbar';

const TimeTrackingList: FC<{
  client?: string;
  family?: string;
  timeTracking?: string;
}> = (props) => {
  // const context = useContext(AppContext);
  // const navigate = useNavigate();

  const testData = [
    {
      id: 1,
      date: moment().format('DD.MM.YYYY'),
      start: moment('08:00:00', 'HH:mm:ss').format('HH:mm:ss'),
      end: moment('16:00:00', 'HH:mm:ss').format('HH:mm:ss'),
      duration: '08h 00m',
      client: null,
      type: 'DAY',
      data: [
        {
          id: 2,
          date: moment().format('DD.MM.YYYY'),
          start: moment('08:30:00', 'HH:mm:ss').format('HH:mm:ss'),
          end: moment('09:30:00', 'HH:mm:ss').format('HH:mm:ss'),
          duration: '01h 00m',
          client: 'CLIENT A',
          type: 'TYPE X',
        },
        {
          id: 3,
          date: moment().format('DD.MM.YYYY'),
          start: moment('10:00:00', 'HH:mm:ss').format('HH:mm:ss'),
          end: moment('12:00:00', 'HH:mm:ss').format('HH:mm:ss'),
          duration: '02h 00m',
          client: 'CLIENT B',
          type: 'TYPE Y',
        },
      ],
    },
    {
      id: 4,
      date: moment().subtract(1, 'days').format('DD.MM.YYYY'),
      start: moment('09:00:00', 'HH:mm:ss').format('HH:mm:ss'),
      end: moment('17:00:00', 'HH:mm:ss').format('HH:mm:ss'),
      duration: '08h 00m',
      client: null,
      type: 'DAY',
      data: [
        {
          id: 5,
          date: moment().subtract(1, 'days').format('DD.MM.YYYY'),
          start: moment('09:30:00', 'HH:mm:ss').format('HH:mm:ss'),
          end: moment('11:00:00', 'HH:mm:ss').format('HH:mm:ss'),
          duration: '01h 30m',
          client: 'CLIENT C',
          type: 'TYPE Z',
        },
      ],
    },
    {
      id: 6,
      date: moment().subtract(2, 'days').format('DD.MM.YYYY'),
      start: moment('10:00:00', 'HH:mm:ss').format('HH:mm:ss'),
      end: moment('15:00:00', 'HH:mm:ss').format('HH:mm:ss'),
      duration: '05h 00m',
      client: null,
      type: 'DAY',
      data: [
        {
          id: 7,
          date: moment().subtract(2, 'days').format('DD.MM.YYYY'),
          start: moment('10:30:00', 'HH:mm:ss').format('HH:mm:ss'),
          end: moment('12:00:00', 'HH:mm:ss').format('HH:mm:ss'),
          duration: '01h 30m',
          client: 'CLIENT D',
          type: 'TYPE A',
        },
      ],
    },
    {
      id: 8,
      date: moment().subtract(3, 'days').format('DD.MM.YYYY'),
      start: moment('07:00:00', 'HH:mm:ss').format('HH:mm:ss'),
      end: moment('14:00:00', 'HH:mm:ss').format('HH:mm:ss'),
      duration: '07h 00m',
      client: null,
      type: 'DAY',
      data: [
        {
          id: 9,
          date: moment().subtract(3, 'days').format('DD.MM.YYYY'),
          start: moment('09:00:00', 'HH:mm:ss').format('HH:mm:ss'),
          end: moment('10:30:00', 'HH:mm:ss').format('HH:mm:ss'),
          duration: '01h 30m',
          client: 'CLIENT E',
          type: 'TYPE B',
        },
      ],
    },
    {
      id: 10,
      date: moment().subtract(4, 'days').format('DD.MM.YYYY'),
      start: moment('06:00:00', 'HH:mm:ss').format('HH:mm:ss'),
      end: moment('14:00:00', 'HH:mm:ss').format('HH:mm:ss'),
      duration: '08h 00m',
      client: null,
      type: 'DAY',
      data: [
        {
          id: 11,
          date: moment().subtract(4, 'days').format('DD.MM.YYYY'),
          start: moment('08:00:00', 'HH:mm:ss').format('HH:mm:ss'),
          end: moment('09:00:00', 'HH:mm:ss').format('HH:mm:ss'),
          duration: '01h 00m',
          client: 'CLIENT F',
          type: 'TYPE C',
        },
      ],
    },
  ];

  // const [rows, setRows] = useState<any[]>([]);

  // useEffect(() => {
  //   setRows(testData);
  // }, [testData, props.timeTracking]);

  const tableColumns: Array<GridColDef> = [
    {
      field: 'date',
      headerName: 'DATE',
      flex: 1,
      hideable: false,
    },
    {
      field: 'start',
      headerName: 'START',
      flex: 1.5,
      hideable: false,
    },
    {
      field: 'end',
      headerName: 'END',
      flex: 1,
    },
    {
      field: 'duration',
      headerName: 'DURATION',
      flex: 1,
    },
  ];

  return (
    <DataGridPro
      sx={{
        fontSize: 17,
        mx: 1,
      }}
      density="compact"
      columns={tableColumns}
      rows={testData}
      getRowId={(row) => row.id}
      disableColumnSelector={true}
      disableColumnFilter={true}
      disableColumnMenu={true}
      hideFooterRowCount={true}
      hideFooterSelectedRowCount={true}
      columnBufferPx={4}
      disableDensitySelector={true}
      autoHeight
      initialState={{
        pagination: { paginationModel: { pageSize: 25 } },
      }}
      pagination
      slots={{
        footer: TimeTrackingListFooter,
        toolbar: TimeTrackingListToolbar,
      }}
      slotProps={{
        footer: { duration: '10h 30m' },
        toolbar: {
          showDayTimer: true,
          showSubTimer: true,
        },
      }}
      getDetailPanelContent={({ row }) => (
        <TimeTrackingListDetail timeTracking={row.data} />
      )}
      getDetailPanelHeight={({ row }) => 'auto'}
    />
  );
};

export default TimeTrackingList;
